import React from 'react';

const MobileComponent = ({ label, mobile, setMobile, isEditable }) => {
  return (
    <div>
      <label>
        {`${label}: `}
        <label>+ </label>
        <input
          className="country-code"
          value={mobile?.countryCode || ''}
          onChange={(e) => setMobile({ ...mobile, countryCode: e.target.value })}
          disabled={!isEditable}
        />
        <input
          value={mobile?.number || ''}
          onChange={(e) => setMobile({ ...mobile, number: e.target.value })}
          disabled={!isEditable}
          placeholder={`Enter ${label}`}
        />
      </label>
    </div>
  );
};

export default MobileComponent;
